<template>
  <div>
      <Table
        :loading="loading"
        border stripe
        :columns="talbeColumns"
        :data="tableList"
        height="400px"
        width="300px"
        @on-selection-change="tableSelete"
        ></Table>
  </div>
</template>

<script>
export default {
    props:{
        data:{
            type:Array,
            default:()=>{
                return []
            }
        },
        code:{
          type:String,
          default:()=>{
              return ''
          }
      },
    },
    data(){
        return{
            loading:false,
            talbeColumns:[
                {
                        type:'selection',
                        align:'center',
                        width:80
                },
                {
                        title:'名称',
                        key:'gridName',
                        align:'center',
                        minWidth:200
                },
            ],
            tableList:[],

        }
    },
     created(){
       setTimeout(()=>{
             this.getList()
       },100)
      
      
    },
    methods:{
        tableSelete(val){
            let arr = []
            if(val.length != 0){
                val.map(item =>{
                    arr.push(item.gridId)
                })
            }
            this.$emit('tableSelect',arr)
        },
        getList(){
            this.loading = true
            this.$get('/gx/pc/grid/queryGridList',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                gridType:1,
                oemCode:parent.vue.oemInfo.oemCode,
                operatorOrgCode:parent.vue.loginInfo.userinfo.orgCode,
                staffName:parent.vue.loginInfo.userinfo.realName,
                orgCode:this.code
            }).then(res=>{
                this.loading = false
                
                if(res.code == 200 ){
                    console.log('上一页的data',this.data);
                    if(this.data != ''){
                   
                         res.dataList.map(item=>{
                            if(this.data.indexOf(item.gridId) > -1){
                                item._checked = true
                            }
                        })

                     }
   
                
                this.tableList = res.dataList
                }
            })
        },
    }
}
</script>

<style>

</style>